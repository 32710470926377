<template>
  <UiPopup
    id="payment-link"
    :model-value="modelValue"
    size="extraBig"
    without-padding
    @update:model-value="emits('update:modelValue', false)"
    @confirm="emits('update:modelValue', false)"
  >
    <template #custom>
      <transition name="list">
        <PropertiesExclusivesGeneratePaymentLinkStep1
          v-if="stepNumber === 1"
          :unit-id="unit?.id || null"
          :holding-hours
          :loading
          @close="emits('update:modelValue', false)"
          @next-step="goToStepTwo"
        />

        <PropertiesExclusivesGeneratePaymentLinkStep2
          v-else-if="stepNumber === 2"
          :link-data="linkData"
          :holding-hours
          :unit
          @close="emits('update:modelValue', false)"
        />
      </transition>
    </template>
  </UiPopup>
</template>

<script setup lang="ts">
import cloneDeep from 'lodash/cloneDeep'
import { useUiStore } from '~/store/ui'
import type { PaymentLinkData } from '@/types'
import type { ExclusiveUnit } from '~/types/properties'
import { LIST_OF_HOURS, SETTING_CODES } from '~/constants'

const emits = defineEmits<{
  (event: 'update:modelValue', value: boolean): void
  (event: 'refetch'): void
}>()

export type CreateEditPopupProps = {
  modelValue: boolean
  unit: ExclusiveUnit | null
}

withDefaults(defineProps<CreateEditPopupProps>(), {
  modelValue: false,
  unit: null,
})
const uiStore = useUiStore()

const stepNumber = ref<number>(1)
const loading = ref(false)
const linkData = ref(null)
const holdingHours = ref<string>('')

const goToStepTwo = async (paymentLinkData: PaymentLinkData) => {
  loading.value = true
  const data = cloneDeep(paymentLinkData)
  delete data.agreed
  try {
    linkData.value = await useCreatePaymentLink(data)
    stepNumber.value++
    emits('refetch')
  } catch {
    uiStore.showSnackBanner('Failed to create payment link, please try again later', 'error')
  } finally {
    loading.value = false
  }
}

onNuxtReady(async () => {
  try {
    if (uiStore.getHoldingHours) {
      holdingHours.value = uiStore.getHoldingHours
    } else {
      const { settings } = await useGetSettingsByCode(SETTING_CODES.PAYMENT_TOKEN_HOLD_TIME)
      holdingHours.value = LIST_OF_HOURS?.find(
        (item) => item.value === settings[SETTING_CODES.PAYMENT_TOKEN_HOLD_TIME]
      )!.text
      uiStore.setHoldingHours(holdingHours.value)
    }
  } catch {
    uiStore.showSnackBanner('Failed to fetch settings, please try again later', 'error')
  }
})
</script>

<style scoped></style>
